import React, { useState } from 'react';
import './InstructionPanel.css'; // Import external CSS file
import { ArrowRight, X } from 'lucide-react';

const InstructionPanel = ({ onSelectModels, selectedModels, onRemoveModel, onGenerate}) => {
    const [aspectRatio, setAspectRatio] = useState('Portrait (3 : 4)');
    const [image, setImage] = useState(null);
    const [promptText, setPromptText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file)); // Create URL for the uploaded image
        }
    };

    const handleClose = () => {
        setImage(null); // Remove the image or close the panel
    };

    const handleGenerate = async () => {
        if (promptText.trim() === '' || selectedModels.length === 0) {
            alert('Please enter a prompt and select at least one model.');
            return;
        }

        setIsGenerating(true);

        const payload = {
            prompt: promptText,
            models: selectedModels.map(model => model.id),
            aspectRatio: aspectRatio.split(' ')[0].toLowerCase() // Convert "Portrait (3 : 4)" to "portrait"
        };

        try {
            const response = await fetch('https://prod.foosh.ai/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Generation failed');
            }

            const data = await response.json();
            console.log('Generation successful:', data);
            onGenerate(data);
        } catch (error) {
            console.error('Error during generation:', error);
            alert('An error occurred during generation. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    const renderSelectedModels = () => {
        const modelDivs = selectedModels.map(model => (
            <div key={model.id} className="selected-model-tag w-full p-2 border border-black rounded">
                <img src={model.thumbnailUrl} alt={model.name} className="selected-model-thumbnail" />
                <span>{model.name}</span>
                <X size={12} onClick={() => onRemoveModel(model)} />
            </div>
        ));

        // Fill with empty divs if needed
        while (modelDivs.length < 2) {
            modelDivs.push(<div />);
        }

        return modelDivs;
    };

    return (
        <div className="instruction-top-row">
            <div className="left-column">
                <div className="reference-container">
                    <div className="reference-header">
                        <span>Reference</span>
                        <div className="reference-icons">
                            <X size={16} className="icon" onClick={handleClose} />
                        </div>
                    </div>
                    <label className="upload-container">
                        {image ? (
                            <img src={image} alt="Uploaded" />
                        ) : (
                            <>
                                <span className="upload-icon">+ Upload image</span>
                                <input type="file" onChange={handleImageUpload} className="upload-input" />
                            </>
                        )}
                    </label>
                </div>
            </div>
            <div className="right-column">
                <textarea
                    className="generation-prompt-textbox"
                    placeholder="You’ve had a vision!? Type it out here."
                    rows="4" /* Adjust the number of rows as needed */
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                />
                <div className="bottom-segment">
                    <button className="studio-page-button" onClick={onSelectModels}>
                        <span className="studio-page-button-icon">+</span> {/* Plus icon */}
                        Select models
                    </button>
                    {renderSelectedModels()}
                    <select
                        value={aspectRatio}
                        onChange={(e) => setAspectRatio(e.target.value)}
                        className="w-full p-2 border border-black rounded"
                    >
                        <option>Portrait (3 : 4)</option>
                        <option>Landscape (4 : 3)</option>
                        <option>Square (1 : 1)</option>
                    </select>
                    <button
                        className="studio-page-button generate-button"
                        onClick={handleGenerate}
                        disabled={isGenerating}
                    >
                        {isGenerating ? 'Generating...' : 'Generate'}
                        {!isGenerating && <ArrowRight size={20} />}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InstructionPanel;