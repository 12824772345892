import React, { useEffect, useState } from 'react';
import './StudioView.css';
import InstructionPanel from './InstructionPanel'; 
import ModelSelectionPopup from './ModelSelectionPopup';
import GeneratedImages from './GeneratedImages';

const StudioView = () => {
    const [isModelPopupOpen, setIsModelPopupOpen] = useState(false);
    const [models, setModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [generationResponse, setGenerationResponse] = useState(null);

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = async () => {
        try {
            const response = await fetch('https://prod.foosh.ai/models?type=all');
            const data = await response.json();
            console.log("API response:", data);
            console.log("Loras from API:", data.loras);
            setModels(data.loras || []);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const handleModelSelection = (selectedModels) => {
        setSelectedModels(selectedModels);
        setIsModelPopupOpen(false);
    };

    const handleGeneration = (generationResponse) => {
        if (generationResponse.success && generationResponse.result) {
            setGenerationResponse(generationResponse);
        }
    };

    return (
        <div className="studio-container">
            <div className="instruction-panel">
                <InstructionPanel
                onSelectModels={() => setIsModelPopupOpen(true)}
                selectedModels={selectedModels}
                onRemoveModel={(model) => setSelectedModels(selectedModels.filter(m => m.id !== model.id))}
                onGenerate={handleGeneration}
                />
            </div>
            <div className="divider"></div>
            <div className="generation-panel flex-grow flex items-center justify-center p-4">
                <GeneratedImages generationResponse={generationResponse}/>
            </div>
            {isModelPopupOpen && (
                <ModelSelectionPopup
                    models={models}
                    selectedModels={selectedModels}
                    onClose={() => setIsModelPopupOpen(false)}
                    onSelectModels={handleModelSelection}
                />
            )}
        </div>
    );
};

export default StudioView;