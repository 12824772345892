import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const Sidebar = ({ filters, onFilterChange }) => {
  return (
    <div className="w-1/4 pr-6">
      {filters.map((filter) => (
        <button
          key={filter.name}
          className="block w-full text-left py-2 px-4 rounded hover:bg-gray-100"
          onClick={() => onFilterChange(filter.name)}
        >
          {filter.name}
        </button>
      ))}
      <Link
        to="/train"
        className="mt-4 flex items-center justify-between w-full py-2 px-4 bg-black text-white rounded hover:bg-gray-800 transition-colors"
      >
        Train new model
        <ArrowRight size={20} />
      </Link>
    </div>
  );
};

export default Sidebar;