import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronDown, ChevronUp, Loader } from 'lucide-react';
import './TrainNewModel.css';

const TrainNewModel = () => {
    const [modelType, setModelType] = useState('Product');
    const [name, setName] = useState('');
    const [triggerWord, setTriggerWord] = useState('');
    const [rank, setRank] = useState(16);
    const [saveEvery, setSaveEvery] = useState(250);
    const [maxSaves, setMaxSaves] = useState(4);
    const [batchSize, setBatchSize] = useState(1);
    const [steps, setSteps] = useState(1000);
    const [zipFile, setZipFile] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [fileError, setFileError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');

    const guidelines = [
        { title: 'Multiple Images 20 - 30:', description: 'Include front, side, and top views.' },
        { title: 'Good Lighting:', description: 'Use evenly lit images with no shadows.' },
        { title: 'High Resolution:', description: 'Upload clear, sharp images (1024x1024+).' },
        { title: 'Simple Background:', description: 'Use plain, neutral backgrounds.' },
        { title: 'Product Only:', description: 'No extra items or distractions.' },
        { title: 'Consistent Appearance:', description: 'Same color and model across images.' },
        { title: 'No Duplicates:', description: 'Use varied images, not identical ones.' },
    ];

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/zip') {
            setZipFile(file);
            setFileError('');
        } else {
            setZipFile(null);
            setFileError('Please upload a zip file containing your training data.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitError('');
        setFileError('');
        setSubmitSuccess('');

        if (!zipFile) {
            setFileError('Please upload a zip file containing your training data.');
            return;
        }

        const formData = new FormData();
        formData.append('image_data', zipFile);
        formData.append('name', name);
        formData.append('trigger_word', triggerWord);
        formData.append('rank', rank);
        formData.append('save_every', saveEvery);
        formData.append('max_saves', maxSaves);
        formData.append('batch_size', batchSize);
        formData.append('steps', steps);
        formData.append('modelType', modelType.toLowerCase());

        setIsLoading(true);

        try {
            console.log("Sending request to API...");
            const response = await fetch('https://prod.foosh.ai/general-lora-training', {
                method: 'POST',
                body: formData,
            });

            console.log("API response status:", response.status);

            if (response.ok) {
                const result = await response.json();
                console.log('Job submitted successfully:', result);
                setSubmitSuccess('Job submitted successfully!');
            } else {
                const errorData = await response.json();
                console.log("API error:", errorData);
                throw new Error(errorData.detail || 'Failed to submit job. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting job:', error);
            setSubmitError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <Link to="/" className="back-link">&larr; Back to Models</Link>
            <h2 className="title">Train new model</h2>
            <div className="content">
                <div className="form-container">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form-group">
                            <label htmlFor="name" className="label">Model name</label>
                            <input
                                id="name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="triggerWord" className="label">Trigger word (optional)</label>
                            <input
                                id="triggerWord"
                                type="text"
                                value={triggerWord}
                                onChange={(e) => setTriggerWord(e.target.value)}
                                className="input"
                            />
                        </div>
                        <div className="form-group">
                            <span className="label">Model Type</span>
                            <div className="radio-group">
                                {['Product', 'Person', 'Style'].map((type) => (
                                    <label key={type} className="radio-label">
                                        <input
                                            type="radio"
                                            value={type}
                                            checked={modelType === type}
                                            onChange={() => setModelType(type)}
                                            className="radio"
                                        />
                                        <span className="radio-text">{type}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="steps" className="label">Steps</label>
                            <input
                                id="steps"
                                type="number"
                                value={steps}
                                onChange={(e) => setSteps(parseInt(e.target.value))}
                                required
                                className="input"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="images" className="label">Upload images (ZIP file containing 20-30 images recommended)</label>
                            <input
                                id="images"
                                type="file"
                                accept=".zip"
                                onChange={handleFileUpload}
                                className="file-input"
                            />
                            <div className="file-info-container">
                                <p className="file-info">
                                    {zipFile ? `${zipFile.name} selected` : 'No file selected'}
                                </p>
                                <button
                                    type="button"
                                    className="show-more-link"
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {showMore ? 'Hide' : 'Show'} additional settings
                                    {showMore ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
                                </button>
                            </div>
                            {fileError && <p className="error-message">{fileError}</p>}
                        </div>
                        {showMore && (
                            <div className="additional-settings">
                                <div className="form-group">
                                    <label htmlFor="rank" className="label">Rank</label>
                                    <input
                                        id="rank"
                                        type="number"
                                        value={rank}
                                        onChange={(e) => setRank(parseInt(e.target.value))}
                                        className="input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="saveEvery" className="label">Save every (optional)</label>
                                    <input
                                        id="saveEvery"
                                        type="number"
                                        value={saveEvery}
                                        onChange={(e) => setSaveEvery(parseInt(e.target.value))}
                                        className="input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="maxSaves" className="label">Max saves (optional)</label>
                                    <input
                                        id="maxSaves"
                                        type="number"
                                        value={maxSaves}
                                        onChange={(e) => setMaxSaves(parseInt(e.target.value))}
                                        className="input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="batchSize" className="label">Batch size</label>
                                    <input
                                        id="batchSize"
                                        type="number"
                                        value={batchSize}
                                        onChange={(e) => setBatchSize(parseInt(e.target.value))}
                                        required
                                        className="input"
                                    />
                                </div>
                            </div>
                        )}
                        {submitSuccess && <p className="success-message">{submitSuccess}</p>}
                        {submitError && <p className="error-message">{submitError}</p>}
                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Loader className="animate-spin mr-2" size={20} />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    Train now
                                    <ArrowRight className="ml-2" size={20} />
                                </>
                            )}
                        </button>
                    </form>
                </div>
                <div className="guidelines-container">
                    <h3 className="guidelines-title">Guidelines</h3>
                    <ul className="guidelines-list">
                        {guidelines.map((guideline, index) => (
                            <li key={index} className="guideline-item">
                                <strong>{guideline.title}</strong>
                                <p className="guideline-description">{guideline.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TrainNewModel;