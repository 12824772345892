import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GalleryView = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://prod.foosh.ai/gallery');
        setImages(response.data.images);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch images. Please try again later.');
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  }

  if (images.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-gray-600">You don't have anything saved yet. Please train a model to get started.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div key={index} className="aspect-w-1 aspect-h-1">
            <img 
              src={image.image_metadata.url} 
              alt={`Gallery image ${index + 1}`} 
              className="object-cover w-full h-full rounded-lg shadow-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryView;