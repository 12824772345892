import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import ModelCard from '../components/ModelCard';

const ModelsView = () => {
    const [filter, setFilter] = useState('Product');
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const filters = [
        { name: 'Product' },
        { name: 'People' },
        { name: 'Style' },
    ];

    useEffect(() => {
        const fetchModels = async () => {
            setLoading(true);
            setError(null);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://prod.foosh.ai/models?type=${filter}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setModels(data.loras);
            } catch (error) {
                console.error('Error fetching models:', error);
                setError('Failed to fetch models. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchModels();
    }, [filter]);

    return (
        <div className="flex p-6">
            <Sidebar filters={filters} onFilterChange={setFilter} />
            <div className="w-3/4">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : models.length > 0 ? (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {models.map((model) => (
                            <ModelCard key={model.id} imageSrc={model.thumbnailUrl} title={model.name} />
                        ))}
                    </div>
                ) : (
                    <div className="text-center mt-20">
                        <h2 className="text-2xl font-bold mb-4">No {filter.toLowerCase()} ready to use.</h2>
                        <p className="mb-4">Train a new model to get started!</p>
                        <Link
                            to="/train"
                            className="inline-block py-2 px-4 bg-black text-white rounded hover:bg-gray-800 transition-colors"
                        >
                            Train New Model
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ModelsView;