import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import Header from './components/Header';
import ModelsView from './views/ModelsView';
import StudioView from './views/StudioView';
import GalleryView from './views/GalleryView';
import TrainNewModel from './views/TrainNewModel';
import SignInView from './views/SignInView';
import SignupView from './views/SignupView';
import ProtectedRoute from './components/ProtectedRoute';
import OAuthCallback from './components/OAuthCallback';

const clerkPubKey = "pk_live_Y2xlcmsuZm9vc2guYWkk";

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <Router>
        <div className="min-h-screen bg-white">
          <Header />
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <ModelsView />
              </ProtectedRoute>
            } />
            <Route path="/sign-in" element={<SignInView />} />
            <Route path="/sign-up" element={<SignupView />} />
            <Route path="/oauth-callback" element={<OAuthCallback />} />
            <Route path="/studio" element={
              <ProtectedRoute>
                <StudioView />
              </ProtectedRoute>
            } />
            <Route path="/gallery" element={
              <ProtectedRoute>
                <GalleryView />
              </ProtectedRoute>
            } />
            <Route path="/train" element={
              <ProtectedRoute>
                <TrainNewModel />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </Router>
    </ClerkProvider>
  );
}

export default App;