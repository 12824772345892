import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';

const OAuthCallback = () => {
  const { handleRedirectCallback } = useClerk();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const result = await handleRedirectCallback();
        if (result.createdSessionId) {
          // Successful sign-in or sign-up
          navigate('/');
        } else {
          // Handle other cases if needed
          navigate('/sign-in');
        }
      } catch (error) {
        console.error('Error handling OAuth callback:', error);
        navigate('/sign-in');
      }
    };

    handleCallback();
  }, [handleRedirectCallback, navigate]);

  return <div>Processing authentication...</div>;
};

export default OAuthCallback;