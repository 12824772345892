import React from 'react';

const ModelCard = ({ imageSrc, title }) => (
    <div className="relative rounded-lg overflow-hidden shadow-md">
        <div className="aspect-w-1 aspect-h-1 w-full">
            <img
                src={imageSrc}
                alt={title}
                className="w-full h-full object-cover"
            />
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
            {title}
        </div>
    </div>
);

export default ModelCard;