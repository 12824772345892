import React, { useState } from 'react';
import { ArrowUp, Plus, Check } from 'lucide-react';

const GeneratedImages = ({ generationResponse }) => {
    const [savedImages, setSavedImages] = useState({});

    const handleButtonClick = (feature) => {
        alert(`The ${feature} feature is currently under development. Stay tuned for updates!`);
    };

    const handleSaveToGallery = async (imageMetadata) => {
        try {
            const response = await fetch('https://prod.foosh.ai/save-to-gallery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    generation_id: generationResponse.generation_id,
                    image_metadata: imageMetadata,
                }),
            });

            if (response.ok) {
                setSavedImages(prev => ({ ...prev, [imageMetadata.url]: true }));
            } else {
                console.error('Failed to save image to gallery');
            }
        } catch (error) {
            console.error('Error saving image to gallery:', error);
        }
    };

    if (!generationResponse || !generationResponse.result || generationResponse.result.length === 0) {
        return (
            <div className="text-center">
                <h2 className="text-3xl font-bold mb-2">Welcome to your Studio!</h2>
                <p className="text-gray-600">Select a model to start your shoot.</p>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-4 gap-4">
            {generationResponse.result.map((image, index) => (
                <div key={index} className="relative">
                    <img
                        src={image.url}
                        alt={`Generated image ${index + 1}`}
                        className="w-full h-auto object-cover rounded-lg"
                    />
                    <div className="absolute bottom-2 right-2 flex space-x-2">
                        <button
                            className="p-2 bg-black bg-opacity-50 rounded-md hover:bg-opacity-75 transition-all duration-200 ease-in-out group"
                            title="AI Upscale"
                            onClick={() => handleButtonClick('AI Upscale')}
                        >
                            <ArrowUp size={20} className="text-white" />
                            <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-black text-white text-xs py-1 px-2 rounded-md">
                                AI Upscale
                            </span>
                        </button>
                        <button
                            className="p-2 bg-black bg-opacity-50 rounded-md hover:bg-opacity-75 transition-all duration-200 ease-in-out group"
                            title="Save to Gallery"
                            onClick={() => handleSaveToGallery(image)}
                            disabled={savedImages[image.url]}
                        >
                            {savedImages[image.url] ? (
                                <Check size={20} className="text-white" />
                            ) : (
                                <Plus size={20} className="text-white" />
                            )}
                            <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-black text-white text-xs py-1 px-2 rounded-md">
                                {savedImages[image.url] ? 'Saved to Gallery' : 'Save to Gallery'}
                            </span>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default GeneratedImages;