import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import './ModelSelectionPopup.css';

const ModelSelectionPopup = ({ models, selectedModels, onClose, onSelectModels }) => {
    const [localSelectedModels, setLocalSelectedModels] = useState(selectedModels);

    const toggleModelSelection = (model) => {
        setLocalSelectedModels(prevSelected => 
            prevSelected.some(m => m.id === model.id)
                ? prevSelected.filter(m => m.id !== model.id)
                : [...prevSelected, model]
        );
    };

    const handleConfirm = () => {
        onSelectModels(localSelectedModels);
    };

    return (
        <div className="model-popup-overlay">
            <div className="model-popup-content">
                <div className="model-popup-header">
                    <h2>Select Models</h2>
                    <button onClick={onClose}><X size={24} /></button>
                </div>
                <div className="model-list">
                    {models.map(model => (
                        <div key={model.id} className="model-item">
                            <label className="model-label">
                                <input
                                    type="checkbox"
                                    checked={localSelectedModels.some(m => m.id === model.id)}
                                    onChange={() => toggleModelSelection(model)}
                                />
                                <img src={model.thumbnailUrl} alt={model.name} className="model-thumbnail" />
                                <span className="model-name">{model.name}</span>
                            </label>
                            <span className="model-type">{model.type}</span>
                        </div>
                    ))}
                </div>
                <div className="model-popup-footer">
                    <button onClick={handleConfirm} className="model-popup-confirm-button">Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default ModelSelectionPopup;