import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SignInButton, SignOutButton, useAuth, useUser } from '@clerk/clerk-react';
import { ChevronDown } from 'lucide-react';

const Header = () => {
  const location = useLocation();
  const { isSignedIn, isLoaded } = useAuth();
  const { user } = useUser();
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (isSignedIn && user) {
      if (user.profileImageUrl) {
        setProfileImageUrl(user.profileImageUrl);
      } else if (user.imageUrl) {
        setProfileImageUrl(user.imageUrl);
      } else {
        setProfileImageUrl(null);
      }
    }
  }, [isSignedIn, user]);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <header className="flex justify-between items-center py-4 px-6 border-b border-gray-200">
      <h1 className="text-2xl font-bold">FOOSH</h1>
      <nav>
        <ul className="flex space-x-6">
          {[
            { name: 'Models', path: '/' },
            { name: 'Studio', path: '/studio' },
            { name: 'Gallery', path: '/gallery' },
          ].map((item) => (
            <li key={item.name}>
              <Link
                to={item.path}
                className={`font-medium ${
                  location.pathname === item.path ? 'text-gray-900' : 'text-gray-400'
                }`}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex items-center space-x-4">
        {isLoaded && (
          isSignedIn ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center space-x-2 focus:outline-none"
              >
                {profileImageUrl ? (
                  <img src={profileImageUrl} alt="Profile" className="w-8 h-8 rounded-full" />
                ) : (
                  <svg className="w-8 h-8 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                  </svg>
                )}
                <span className="text-sm font-medium">{user.fullName}</span>
                <ChevronDown size={16} />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    UPGRADE
                  </button>
                  <SignOutButton>
                    <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Sign Out
                    </button>
                  </SignOutButton>
                </div>
              )}
            </div>
          ) : (
            <SignInButton mode="modal">
              <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                Sign In
              </button>
            </SignInButton>
          )
        )}
      </div>
    </header>
  );
};

export default Header;